import { createTheme } from "@mui/material/styles"

export const colorPalette = {
  white: "rgba(255, 255, 255, 1)",

  deepPurple: "rgba(32, 10, 58, 1)",
  deepPurpleO03: "rgb(248, 248, 249)", // equivalent of  rgba(32, 10, 58, 0.03)
  deepPurpleO05: "rgb(244, 243, 245)", // equivalent of rgba(32, 10, 58, 0.05)
  deepPurpleO10: "rgb(233, 231, 235)", // equivalent of rgba(32, 10, 58, 0.1)
  deepPurpleO15: "rgb(222, 218, 225)", // equivalent of rgba(32, 10, 58, 0.15)
  deepPurpleO20: "rgb(210, 206, 216)", // equivalent of rgba(32, 10, 58, 0.20)
  deepPurpleO30: "rgb(188, 182, 196)", // equivalent of rgba(32, 10, 58, 0.30)
  deepPurpleO50: "rgb(143, 132, 156)", // equivalent of rgba(32, 10, 58, 0.50)
  deepPurpleO60: "rgb(121, 108, 137)", // equivalent of rgba(32, 10, 58, 0.60)
  deepPurpleO70: "rgb(99, 84, 117)", // equivalent of rgba(32, 10, 58, 0.70)
  deepPurpleO80: "rgb(77, 59, 97)", // equivalent of rgba(32, 10, 58, 0.80)
  deepPurpleO90: "rgb(54, 35, 78)", // equivalent of rgba(32, 10, 58, 0.90)

  deepPurpleA60: "rgba(32, 10, 58, 0.60)",

  luminousGreen: "rgba(3, 255, 209, 1)",
  luminousGreenO30: "rgb(175, 255, 241)", // equivalent of "rgba(2, 255, 209, 0.30)",
  luminousGreenO10: "rgba(3, 255, 209, 0.1)",

  luminousYellow: "rgb(255,216,11)",
  luminousPurple: "rgba(157, 89, 244, 1)",
  luminousPurpleO15: "rgba(157, 89, 244, 0.15)",
  luminousPurpleO30: "rgba(157, 89, 244, 0.30)",
  luminousPurpleO10: "rgba(157, 89, 244, 0.05)",

  luminousCoral: "rgb(254, 119, 76)",
  luminousCoralO20: "rgb(254, 119, 76, 0.2)",

  surfieGreen: "rgba(17, 132, 133, 1)",
  smoky: "rgba(99, 83, 117, 1)",
  graySuit: "rgba(199, 193, 205, 1)",
}

const functionalColorPalette = {
  iconButtonColor: colorPalette.deepPurpleO70,
}

export const theme = createTheme({
  palette: {
    primary: {
      light: colorPalette.deepPurpleO50,
      main: colorPalette.deepPurple,
      dark: colorPalette.deepPurple,
      contrastText: "#fff",
    },
    secondary: {
      light: colorPalette.luminousGreen,
      main: colorPalette.luminousGreen,
      dark: colorPalette.surfieGreen,
      contrastText: "#000",
    },
    error: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#000",
    },
    text: {
      primary: colorPalette.deepPurple,
    },
  },
  typography: {
    fontFamily: ["Roboto", "sans-serif"].join(","),
    h5: {
      fontWeight: "bold",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: colorPalette.deepPurpleO30,
          },
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            color: functionalColorPalette.iconButtonColor,
          },
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: colorPalette.deepPurple,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: colorPalette.deepPurple,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: functionalColorPalette.iconButtonColor,
          "&:hover": {
            backgroundColor: colorPalette.deepPurpleO03,
            "@media (hover: none)": {
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: colorPalette.deepPurpleO50,
        },
      },
    },
  },
})
