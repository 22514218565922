import { RELEASED_LABEL, type ResourceKind } from "../utils/entities/sdk.resource.types"

export const OQS = {
  isPublic: {
    "#EQUALS": [`$labels.'${RELEASED_LABEL}'`, true],
  },
  isPrivate: {
    "#OR": [{ "#IS_NULL": [`$labels.'${RELEASED_LABEL}'`] }, { "#EQUALS": [`$labels.'${RELEASED_LABEL}'`, false] }],
  },
  isObservable: {
    "#EQUALS": ["$kind", "catalog.hubocean.io/observable"],
  },
  hasKind: (kind: ResourceKind) => ({ "#EQUALS": ["$kind", kind] }),
}

export const generateRangeOverlapOqs = (timeRange: [number, number]) => ({
  "#OR": [
    {
      "#AND": [
        {
          "#GREATER_THAN_OR_EQUALS": [
            timeRange[0],
            {
              "#KEYREF": ["$spec.details.value", 0],
            },
          ],
        },
        {
          "#LESS_THAN_OR_EQUALS": [
            timeRange[0],
            {
              "#KEYREF": ["$spec.details.value", 1],
            },
          ],
        },
      ],
    },
    {
      "#AND": [
        {
          "#GREATER_THAN_OR_EQUALS": [
            timeRange[1],
            {
              "#KEYREF": ["$spec.details.value", 0],
            },
          ],
        },
        {
          "#LESS_THAN_OR_EQUALS": [
            timeRange[1],
            {
              "#KEYREF": ["$spec.details.value", 1],
            },
          ],
        },
      ],
    },
    {
      "#AND": [
        {
          "#GREATER_THAN_OR_EQUALS": [
            {
              "#KEYREF": ["$spec.details.value", 0],
            },
            timeRange[0],
          ],
        },
        {
          "#LESS_THAN_OR_EQUALS": [
            {
              "#KEYREF": ["$spec.details.value", 0],
            },
            timeRange[1],
          ],
        },
      ],
    },
    {
      "#AND": [
        {
          "#GREATER_THAN_OR_EQUALS": [
            {
              "#KEYREF": ["$spec.details.value", 1],
            },
            timeRange[0],
          ],
        },
        {
          "#LESS_THAN_OR_EQUALS": [
            {
              "#KEYREF": ["$spec.details.value", 1],
            },
            timeRange[1],
          ],
        },
      ],
    },
  ],
})
