import type {
  ApiGetFileBody,
  ApiUploadFileBody,
  CreateRawFileDto,
  DeleteRawFileResponse,
  RawFileFilters,
  RawFileInfoGet,
  UploadResponse,
} from "../../api/raw.types"
import { generatePatchOperations } from "../../api/requests.utils"
import type { ApiResponse, DeleteResponse } from "../../api/response.types"
import type {
  CreateSchemaDto,
  DataPointsCreateDto,
  DataPointsNDJsonDto,
  DtaPointsCreateResponse,
  TableSchemaGetDto,
} from "../../api/schema.types"
import type { TimeRangeSeconds } from "../../frontendTypes/datasets.types"
import { getResourceKindForResourceType } from "../../frontendTypes/datasetType.helpers"
import type { CreateDataCollectionDto, DataCollectionDtoGet } from "../utils/entities/sdk.dataCollection.types"
import {
  type APIResultsDto,
  type APIResultsRawFiles,
  type DatasetDtoCreate,
  type DatasetDtoGet,
  type EditDatasetDto,
} from "../utils/entities/sdk.dataset.types"
import type {
  ObservableDtoCreate,
  ObservableDtoGet,
  TimeRangeObservableValue,
} from "../utils/entities/sdk.observable.types"
import { ResourceKind, type ResourceDtoCreate, type ResourceTypeOdp } from "../utils/entities/sdk.resource.types"
import type { LimitedStreamOptions, requestFnType } from "../utils/requests/sdk.request.types"
import { generateRangeOverlapOqs, OQS } from "./oqs.helpers"

export class DatasetsSDK {
  requestFn: requestFnType

  constructor(requestFn: requestFnType) {
    this.requestFn = requestFn
  }

  async getObservablesForResource({
    resourceId,
    resourceType,
    isPublic,
  }: {
    resourceId: string
    resourceType: ResourceTypeOdp
    isPublic?: boolean
  }): Promise<ApiResponse<APIResultsDto<ObservableDtoGet<any>>>> {
    const oqsConditions: any[] = [
      OQS.isObservable,
      {
        "#EQUALS": ["$spec.ref", `${getResourceKindForResourceType(resourceType)}/${resourceId}`],
      },
    ]

    if (isPublic) oqsConditions.push(OQS.isPublic)

    return await this.requestFn("catalog/list", {
      method: "POST",
      body: {
        "#AND": oqsConditions,
      },
    })
  }

  async getGeometryObservables({
    geometry,
    resourceKind,
    isPublic,
  }: {
    geometry: GeoJSON.Polygon
    resourceKind: ResourceKind
    isPublic?: boolean
  }): Promise<ApiResponse<APIResultsDto<ObservableDtoGet<GeoJSON.Geometry>>>> {
    const oqsConditions: any[] = [
      OQS.isObservable,
      {
        "#ST_INTERSECTS": ["$spec.details.value", geometry],
      },
      { "#REGEX_LIKE": ["$spec.ref", `^${resourceKind}`] },
    ]

    if (isPublic) {
      oqsConditions.push(OQS.isPublic)
    }

    return await this.requestFn("catalog/list", {
      method: "POST",
      body: {
        "#AND": oqsConditions,
      },
    })
  }

  async getTimeRangeObservables({
    timeRange,
    isPublic,
  }: {
    timeRange: TimeRangeSeconds
    isPublic?: boolean
  }): Promise<ApiResponse<APIResultsDto<ObservableDtoGet<TimeRangeObservableValue>>>> {
    const rangeOverlapOqs = generateRangeOverlapOqs(timeRange)

    const oqsConditions: any[] = [rangeOverlapOqs, OQS.isObservable]
    if (isPublic) oqsConditions.push(OQS.isPublic)

    return await this.requestFn("catalog/list", {
      method: "POST",
      body: {
        "#AND": oqsConditions,
      },
    })
  }

  async getDatasetByIdApi(datasetId: string): Promise<ApiResponse<DatasetDtoGet>> {
    return await this.requestFn(`catalog/${datasetId}`)
  }

  async updateResourceByIdApi(datasetId: string, update: EditDatasetDto): Promise<any> {
    return await this.requestFn(`catalog?kind=hubocean.io/catalog&either_id=${datasetId}`, {
      method: "PATCH",
      body: {
        diff: generatePatchOperations(update),
      },
    })
  }

  async getDatasetByNameApi(nameID: string): Promise<ApiResponse<DatasetDtoGet>> {
    return await this.requestFn(`catalog/catalog.hubocean.io/dataset/${nameID}`)
  }

  async getDataCollectionByNameApi(nameID: string): Promise<ApiResponse<DataCollectionDtoGet>> {
    return await this.requestFn(`catalog/catalog.hubocean.io/dataCollection/${nameID}`)
  }

  async getSchemaByDatasetNameId(datasetNameId: string): Promise<ApiResponse<TableSchemaGetDto>> {
    return await this.requestFn(`data/catalog.hubocean.io/dataset/${datasetNameId}/schema`)
  }

  async getDatapointsByDatasetNameId({
    datasetNameId,
    limitedStreamOptions,
  }: {
    datasetNameId: string
    limitedStreamOptions?: LimitedStreamOptions
  }): Promise<ApiResponse<DataPointsNDJsonDto>> {
    return await this.requestFn<DataPointsNDJsonDto>(
      `data/catalog.hubocean.io/dataset/${datasetNameId}/list${
        limitedStreamOptions?.maxObjects ? `?limit=${limitedStreamOptions?.maxObjects}` : ""
      }`,
      {
        method: "POST",
        useLimitedStream: true,
        limitedStreamOptions,
      }
    )
  }

  async addDatasetSchemaByNameId({
    datasetNameId,
    schemaDto,
  }: {
    datasetNameId: string
    schemaDto: CreateSchemaDto
  }): Promise<ApiResponse<CreateSchemaDto>> {
    return await this.requestFn(`data/catalog.hubocean.io/dataset/${datasetNameId}/schema`, {
      method: "POST",
      body: schemaDto,
    })
  }

  async deleteDatasetSchemaByUuid(datasetUuid: string): Promise<ApiResponse<DeleteResponse>> {
    return await this.requestFn(`data/${datasetUuid}/schema?delete_data=true`, {
      method: "DELETE",
    })
  }

  async deleteDatasetSchemaByNameId(datasetUuid: string): Promise<ApiResponse<DeleteResponse>> {
    return await this.requestFn(`data/${ResourceKind.dataset}/${datasetUuid}/schema?delete_data=true`, {
      method: "DELETE",
    })
  }

  async deleteDataset(datasetId: string): Promise<ApiResponse<{ status: string }>> {
    return await this.requestFn(`catalog/${datasetId}`, { method: "DELETE" })
  }

  async addResource<T extends ResourceDtoCreate, R>(dto: T): Promise<ApiResponse<R>> {
    return await this.requestFn("catalog", {
      method: "POST",
      body: dto,
    })
  }

  async addDataset(datasetDto: DatasetDtoCreate) {
    return await this.addResource<DatasetDtoCreate, DatasetDtoGet>(datasetDto)
  }

  async addDataCollection(dataCollectionDto: CreateDataCollectionDto) {
    return await this.addResource<CreateDataCollectionDto, DataCollectionDtoGet>(dataCollectionDto)
  }

  async createDataPointsByNameId({
    datasetNameId,
    dataPointsDto,
  }: {
    datasetNameId: string
    dataPointsDto: DataPointsCreateDto
  }): Promise<ApiResponse<DtaPointsCreateResponse>> {
    return await this.requestFn(`data/catalog.hubocean.io/dataset/${datasetNameId}`, {
      method: "POST",
      body: dataPointsDto,
    })
  }

  async addObservable<T>(observableDto: ObservableDtoCreate<T>): Promise<ApiResponse<ObservableDtoGet<T>>> {
    return await this.requestFn("catalog", {
      method: "POST",
      body: observableDto,
    })
  }

  async getRawFilesInfoForDatasetNameID({
    datasetId,
    fileFilters,
    limit,
    page,
  }: {
    datasetId: string
    fileFilters?: RawFileFilters
    limit?: number
    page?: string
  }): Promise<ApiResponse<APIResultsRawFiles<RawFileInfoGet>>> {
    return await this.requestFn(`data/${ResourceKind.dataset}/${datasetId}/list`, {
      method: "POST",
      body: fileFilters ?? { files: "*" }, // this doesn't do anything, but sending empty body throws error
      params: { page_size: limit, page },
    })
  }

  async createRawFileByDatasetNameID({
    dto,
    datasetNameId,
  }: {
    datasetNameId: string
    dto: CreateRawFileDto
  }): Promise<ApiResponse<RawFileInfoGet>> {
    return await this.requestFn(`data/${ResourceKind.dataset}/${datasetNameId}`, {
      method: "POST",
      body: dto,
    })
  }

  async uploadRawFileByDatasetNameID({
    datasetNameId: datasetId,
    fileName,
    file,
  }: ApiUploadFileBody): Promise<ApiResponse<UploadResponse>> {
    return await this.requestFn(`data/${ResourceKind.dataset}/${datasetId}/${fileName}`, {
      method: "PATCH",
      body: file,
      omitContentType: true,
    })
  }

  async getRawFileByDatasetNameID({ datasetNameId: datasetId, fileName }: ApiGetFileBody): Promise<any> {
    return await this.requestFn(`data/${ResourceKind.dataset}/${datasetId}/${fileName}`, {
      method: "GET",
      isFileDownloadRequest: true,
    })
  }

  async deleteRawFileByDatasetNameID({
    datasetNameId: datasetId,
    fileName,
  }: ApiGetFileBody): Promise<ApiResponse<DeleteRawFileResponse>> {
    return await this.requestFn(`data/${ResourceKind.dataset}/${datasetId}/${fileName}`, {
      method: "DELETE",
    })
  }
}
