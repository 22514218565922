import { QueryStatus, skipToken } from "@reduxjs/toolkit/query"
import { useSearchQueryArgs } from "../../pages/Catalog/components/CatalogResults/catalogResults.helpers"
import { useCheckAnonymousUser } from "../auth/userHooks"
import type { DataCollection } from "../frontendTypes/dataCollection.types"
import {
  useGetSharedCollectionsQuery,
  useGetSharedDatasetsInCollectionQuery,
  useGetSharedDatasetsQuery,
} from "../redux/queries/access.queries"
import {
  useGetDataCollectionSearchResultsQuery,
  useGetDatasetSearchResultsQuery,
} from "../redux/queries/catalog.queries"
import { useGetCollectionInternalDatasetsQuery } from "../redux/queries/dataset.queries"
import { useAppSelector } from "../redux/store"
import { ResourceTypeOdp } from "../sdk/utils/entities/sdk.resource.types"

/**
 * @param isPublic if set to true, will only return collections with label public
 * @param isInternallyShared if set to true, will only return collections with label internally shared,
 * if set to false, will only return collections without label internally shared or with it's value set to false
 * if undefined with ignore the label internally shared
 */
export const useCollections = ({
  isPublic,
  isInternallyShared,
}: {
  isPublic: boolean
  isInternallyShared?: boolean
}) => {
  const isAnonymous = useCheckAnonymousUser()

  const {
    queryArgs,
    isLoading: isQueryParamsLoading,
    noPossibleResults,
    isFetching: isQueryParamsFetching,
  } = useSearchQueryArgs({ resourceType: ResourceTypeOdp.Collection, isPublic, isAnonymous })

  const shouldSkipFetchingCollections = isQueryParamsLoading || noPossibleResults

  const collectionHookResults = useGetDataCollectionSearchResultsQuery(
    shouldSkipFetchingCollections ? skipToken : { ...queryArgs, isPublic, isInternallyShared, isAnonymous },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  const {
    data: sharedCollections,
    error: sharedError,
    isFetching: sharedFetching,
  } = useGetSharedCollectionsQuery(isInternallyShared || isAnonymous ? skipToken : undefined)

  const collections = collectionHookResults.data

  const errors = []
  if (sharedError) errors.push(sharedError)
  if (collectionHookResults.error) errors.push(collectionHookResults.error)

  const isFetching = collectionHookResults.isFetching || isQueryParamsFetching || sharedFetching

  return { collections, sharedCollections, isFetching, noPossibleResults, errors }
}

/**
 * @param isPublic if set to true, will only return datasets with label public
 * @param isInternallyShared if set to true, will only return datasets with label internally shared,
 * if set to false, will only return datasets without label internally shared or with it's value set to false
 * if undefined with ignore the label internally shared
 */
export const useDatasets = ({ isPublic, isInternallyShared }: { isPublic: boolean; isInternallyShared?: boolean }) => {
  const isAnonymous = useCheckAnonymousUser()

  const {
    queryArgs,
    isLoading: isQueryParamsLoading,
    noPossibleResults,
    isFetching: isQueryParamsFetching,
  } = useSearchQueryArgs({ resourceType: ResourceTypeOdp.Dataset, isPublic, isAnonymous })

  const shouldSkip = isQueryParamsLoading || noPossibleResults
  const getDatasetsHookResults = useGetDatasetSearchResultsQuery(
    shouldSkip ? skipToken : { ...queryArgs, isPublic, isInternallyShared, isAnonymous },
    {
      refetchOnMountOrArgChange: true,
    }
  )

  const {
    data: sharedDatasets,
    error: sharedError,
    isFetching: sharedFetching,
  } = useGetSharedDatasetsQuery(isInternallyShared || isAnonymous ? skipToken : undefined)

  const errors = []
  if (sharedError) errors.push(sharedError)
  if (getDatasetsHookResults.error) errors.push(getDatasetsHookResults.error)

  const { data: datasets, isFetching: isFetchingDatasets } = getDatasetsHookResults

  const isFetching = isFetchingDatasets || isQueryParamsFetching || sharedFetching

  return { datasets, sharedDatasets, isFetching, noPossibleResults, errors }
}

export const useSharedCollectionDatasets = (collection: DataCollection) => {
  const {
    data: datasets,
    isFetching,
    error,
  } = useGetCollectionInternalDatasetsQuery(collection.nameId, { refetchOnMountOrArgChange: true })

  const {
    data: sharedDatasets,
    isFetching: sharedFetching,
    error: sharedError,
  } = useGetSharedDatasetsInCollectionQuery(collection.uuid)

  return {
    data: [...(datasets ?? []), ...(sharedDatasets ?? [])],
    isFetching: isFetching || sharedFetching,
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    error: error || sharedError,
  }
}

export const useCheckMyData401 = () => {
  const myCollectionsQuery = useAppSelector(state => {
    const keys = Object.keys(state.datasetsApi.queries)
    const key = keys.find(k => k.startsWith("getDataCollectionSearchResults"))
    if (key) return state.datasetsApi.queries[key]
  })

  const myDatasetsQuery = useAppSelector(state => {
    const keys = Object.keys(state.datasetsApi.queries)
    const key = keys.find(k => k.startsWith("getDatasetSearchResults"))
    if (key) return state.datasetsApi.queries[key]
  })

  const myCollectionsFulfilled = myCollectionsQuery?.status === "fulfilled"
  const myDatasetsFullfilled = myDatasetsQuery?.status === "fulfilled"

  const myCollectionsErrorIs401 =
    myCollectionsQuery?.error && "status" in myCollectionsQuery.error && myCollectionsQuery.error?.status === 401

  const myDatasetsErrorIs401 =
    myDatasetsQuery?.error && "status" in myDatasetsQuery.error && myDatasetsQuery.error?.status === 401

  return {
    has401Error: !!myCollectionsErrorIs401 ?? !!myDatasetsErrorIs401,
    isFulfilled: myCollectionsFulfilled || myDatasetsFullfilled,
  }
}

export const useCheckCatalog401 = () => {
  const catalogCollectionsQuery = useAppSelector(
    state =>
      // eslint-disable-next-line @typescript-eslint/quotes
      state.datasetsApi.queries['getDataCollectionSearchResults({"filterIds":null,"isPublic":true,"searchString":""})']
  )

  const catalogDatasetsQuery = useAppSelector(
    state =>
      // eslint-disable-next-line @typescript-eslint/quotes
      state.datasetsApi.queries['getDatasetSearchResults({"filterIds":null,"isPublic":true,"searchString":""})']
  )

  const catalogCollectionsFulfilled = catalogCollectionsQuery?.status === QueryStatus.fulfilled
  const catalogDatasetsFullfilled = catalogDatasetsQuery?.status === QueryStatus.fulfilled

  const catalogCollectionsPending = catalogCollectionsQuery?.status === QueryStatus.pending
  const catalogDatasetsPending = catalogDatasetsQuery?.status === QueryStatus.pending

  const isFetching = catalogCollectionsPending || catalogDatasetsPending

  const catalogCollectionsErrorIs401: boolean =
    !!catalogCollectionsQuery?.error &&
    "status" in catalogCollectionsQuery.error &&
    catalogCollectionsQuery.error?.status === 401

  const catalogDatasetsErrorIs401 =
    catalogDatasetsQuery?.error && "status" in catalogDatasetsQuery.error && catalogDatasetsQuery.error?.status === 401

  return {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    has401Error: !!catalogCollectionsErrorIs401 ?? !!catalogDatasetsErrorIs401,
    isFulfilled: catalogCollectionsFulfilled || catalogDatasetsFullfilled,
    isFetching,
  }
}
