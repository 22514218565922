import { Box, Grid } from "@mui/material"
import React, { useMemo } from "react"
import Minimap from "../../pages/CatalogCollection/components/Minimap"
import { useCheckAnonymousUser } from "../../utils/auth/userHooks"
import { useGetObservablesForResourceQuery } from "../../utils/redux/queries/dataset.queries"
import { ObservableCls } from "../../utils/sdk/utils/entities/sdk.observable.types"
import type { ResourceTypeOdp } from "../../utils/sdk/utils/entities/sdk.resource.types"
import { Loader } from "../Loader/Loader"
import CatalogObservable from "./components/CatalogObservable/CatalogObservable"

type Props = {
  nameId: string
  type: ResourceTypeOdp
}

const CatAllObservables = (p: Props) => {
  const isAnonymous = useCheckAnonymousUser()

  const { data: observables, isLoading: observablesLoading } = useGetObservablesForResourceQuery({
    resourceId: p.nameId,
    resourceType: p.type,
    isPublic: true,
    isAnonymous,
  })

  const obsSorted = useMemo(
    () =>
      observables
        ? [...observables].sort((a, b) =>
            a.spec.observable_class > b.spec.observable_class
              ? -1
              : b.spec.observable_class > a.spec.observable_class
              ? 1
              : 0
          )
        : [],
    [observables]
  )

  const hasBbox = useMemo(
    () => !!observables?.find(el => el.spec.observable_class === ObservableCls.BBox),
    [observables]
  )

  if (observablesLoading) {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={400}>
        <Loader />
      </Box>
    )
  }

  return (
    <>
      {!hasBbox && (
        <Box sx={{ mb: 2 }} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <Minimap height={"400px"} containerId={"cat_empty_map"} />
        </Box>
      )}

      {obsSorted.map(el => (
        <Grid item key={"observable_" + el?.metadata.name}>
          <CatalogObservable {...el} />
        </Grid>
      ))}
    </>
  )
}

export default React.memo(CatAllObservables)
