import { skipToken } from "@reduxjs/toolkit/query"
import _ from "lodash"
import { useEffect, useState } from "react"
import { createTimeRangeSeconds } from "../../../../utils/frontendTypes/datasets.types"
import {
  useGetIdsFromBBoxObservableQuery,
  useGetIdsFromTimerangeObservableQuery,
} from "../../../../utils/redux/queries/dataset.queries"
import { useAppSelector } from "../../../../utils/redux/store"
import type { ResourceTypeOdp } from "../../../../utils/sdk/utils/entities/sdk.resource.types"

/**
 * Gets observables that fit filter criteria.
 * Gets qualified name ids from those observables and returns it as filterIDs
 * those filter Ids can be used to get resource manifests and displaying them in the catalog
 * @returns arguments that can be used in query for resource manifests
 */
export const useSearchQueryArgs = ({
  resourceType,
  isPublic,
  isAnonymous,
}: {
  resourceType: ResourceTypeOdp
  isPublic: boolean
  isAnonymous?: boolean
}) => {
  const { searchString, boundingBox, startYear, endYear } = useAppSelector(state =>
    isPublic ? state.catalogReducer : state.datasetsReducer.filters
  )

  const [queryArgs, setQueryArgs] = useState<{ searchString: string; filterIds: string[] | null }>({
    searchString,
    filterIds: [],
  })

  const noPossibleResults = queryArgs.filterIds?.length === 0 && !isAnonymous

  const shouldUseBboxFiltering = !!boundingBox
  const shouldUseTimeRangeFiltering = !!startYear || !!endYear

  const {
    data: bboxFilterIds,
    isLoading: bboxFilterLoading,
    isFetching: isBBoxFetching,
  } = useGetIdsFromBBoxObservableQuery(
    isAnonymous || !boundingBox ? skipToken : { bbox: boundingBox, resourceType, isPublic }
  )

  const timeRange = createTimeRangeSeconds(startYear, endYear)

  const {
    data: timeRangeFilteredIds,
    isLoading: timeRangeFilterLoading,
    isFetching: isTimeRangeFetching,
  } = useGetIdsFromTimerangeObservableQuery(timeRange && !isAnonymous ? { timeRange, isPublic } : skipToken)

  const isLoading = bboxFilterLoading || timeRangeFilterLoading
  const isFetching = isBBoxFetching || isTimeRangeFetching

  // sets query args based on
  useEffect(() => {
    if (isAnonymous) {
      return setQueryArgs({ searchString, filterIds: null })
    }

    if (isBBoxFetching || isTimeRangeFetching) return

    if (!shouldUseBboxFiltering && !shouldUseTimeRangeFiltering) {
      setQueryArgs({ searchString, filterIds: null })
      return
    }

    let allAcceptableIds = null
    if (shouldUseBboxFiltering) allAcceptableIds = bboxFilterIds
    if (shouldUseTimeRangeFiltering) {
      if (allAcceptableIds === null) allAcceptableIds = timeRangeFilteredIds
      else allAcceptableIds = _.intersection(allAcceptableIds, timeRangeFilteredIds)
    }

    setQueryArgs({ searchString, filterIds: allAcceptableIds ?? null })
  }, [
    bboxFilterIds,
    timeRangeFilteredIds,
    searchString,
    shouldUseBboxFiltering,
    shouldUseTimeRangeFiltering,
    isBBoxFetching,
    isTimeRangeFetching,
    isAnonymous,
  ])

  return { queryArgs, isFetching, isLoading, noPossibleResults }
}

export const trimCatalogDescription = (description: string) => {
  const shouldBeTrimmed = description.length > 300
  return shouldBeTrimmed ? description.substring(0, 300) + "..." : description
}
