import { createCollectionRefernceName } from "../../api/requests.utils"
import { type ApiResponse } from "../../api/response.types"
import { getResourceKindForResourceType } from "../../frontendTypes/datasetType.helpers"
import type { DataCollectionDtoGet } from "../utils/entities/sdk.dataCollection.types"
import { type APIResultsDto, type DatasetDtoGet } from "../utils/entities/sdk.dataset.types"
import type { ObservableDtoGet } from "../utils/entities/sdk.observable.types"
import {
  INTERNALY_SHARED_LABEL,
  ResourceKind,
  ResourceTypeOdp,
  type OqsCondition,
  type ResourceFilterParams,
} from "../utils/entities/sdk.resource.types"
import { API_URL, generateCaseInsensitivePattern } from "../utils/requests/sdk.request.helpers"
import type { requestFnType } from "../utils/requests/sdk.request.types"
import { standardJsonRequest } from "../utils/requests/sdk.requests"
import { OQS } from "./oqs.helpers"

export class CatalogSDK {
  requestFn: requestFnType

  constructor(requestFn: requestFnType) {
    this.requestFn = requestFn
  }

  async getDatasetSearchResult({
    typedSearchString,
    filterIds,
    searchInTags = true,
    isPublic,
    collectionId,
    isInternallyShared,
  }: ResourceFilterParams & { collectionId?: string }): Promise<ApiResponse<APIResultsDto<DatasetDtoGet>>> {
    const oqsCollectionIdCondition = collectionId
      ? [{ "#EQUALS": ["$spec.data_collection", createCollectionRefernceName(collectionId)] }]
      : undefined

    return await this.getResourceSearchResults<DatasetDtoGet>({
      typedSearchString,
      filterIds,
      kind: ResourceKind.dataset,
      searchInTags,
      resourceAvailability: isPublic ? "public" : "private",
      otherOqsConditions: oqsCollectionIdCondition,
      isInternallyShared,
    })
  }

  async getResourceSearchResults<T>({
    typedSearchString,
    kind,
    searchFields = ["description", "display_name", "name"],
    filterIds,
    searchInTags = true,
    resourceAvailability,
    otherOqsConditions = [],
    isInternallyShared,
  }: ResourceFilterParams & {
    kind: ResourceKind
    searchFields?: string[]
    otherOqsConditions?: OqsCondition[]
    resourceAvailability: "public" | "private"
  }): Promise<ApiResponse<APIResultsDto<T>>> {
    const pattern = typedSearchString ? generateCaseInsensitivePattern(typedSearchString) : ""

    const oqsConditions: OqsCondition[] = [OQS.hasKind(kind)]

    if (resourceAvailability === "public") {
      oqsConditions.push(OQS.isPublic)
    }

    if (resourceAvailability === "private") {
      oqsConditions.push(OQS.isPrivate)
    }

    if (isInternallyShared === true) {
      oqsConditions.push({
        "#EQUALS": [`$labels.'${INTERNALY_SHARED_LABEL}'`, true],
      })
    }

    if (isInternallyShared === false) {
      oqsConditions.push({
        "#OR": [
          { "#IS_NULL": [`$labels.'${INTERNALY_SHARED_LABEL}'`] },
          { "#EQUALS": [`$labels.'${INTERNALY_SHARED_LABEL}'`, false] },
        ],
      })
    }
    // if isInternallyShared is undefined we don't check for the label

    if (searchFields?.length) {
      oqsConditions.push({
        "#OR": searchFields.map(el => ({ "#REGEX_LIKE": [`$${el}`, pattern] })),
      })
    }
    if (filterIds?.length) oqsConditions.push({ "#WITHIN": ["$metadata.name", [...filterIds]] })
    if (searchInTags && typedSearchString) {
      oqsConditions[2]["#OR"]?.push({ "#WITHIN": [typedSearchString, "$spec.tags"] })
    }

    return await this.requestFn("catalog/list", {
      method: "POST",
      body: {
        "#AND": [...oqsConditions, ...otherOqsConditions],
      },
    })
  }

  async getResourceSearchReslutOpen({
    searchString,
    resourceKind,
  }: {
    searchString?: string
    resourceKind: ResourceKind
  }): Promise<ApiResponse<APIResultsDto<DatasetDtoGet | DataCollectionDtoGet>>> {
    const url = new URL(`${API_URL}/api/public/catalog/v1/metadata/search/`)

    url.searchParams.append("kind", resourceKind)
    if (searchString) url.searchParams.append("keywords", searchString)

    return await standardJsonRequest([url, { method: "GET" }])
  }

  async getDatasetSearchResultOpen({
    typedSearchString,
  }: ResourceFilterParams & { collectionId?: string }): Promise<ApiResponse<APIResultsDto<DatasetDtoGet>>> {
    return (await this.getResourceSearchReslutOpen({
      searchString: typedSearchString,
      resourceKind: ResourceKind.dataset,
    })) as ApiResponse<APIResultsDto<DatasetDtoGet>>
  }

  async getCollectionsSearchResultOpen({
    typedSearchString,
  }: ResourceFilterParams & { collectionId?: string }): Promise<ApiResponse<APIResultsDto<DataCollectionDtoGet>>> {
    return (await this.getResourceSearchReslutOpen({
      searchString: typedSearchString,
      resourceKind: ResourceKind.collection,
    })) as ApiResponse<APIResultsDto<DataCollectionDtoGet>>
  }

  async getRelatedResources({
    resourceKind,
    ref,
  }: {
    resourceKind: ResourceKind
    ref: string
  }): Promise<ApiResponse<APIResultsDto<DatasetDtoGet | ObservableDtoGet<any>>>> {
    const url = new URL(`${API_URL}/api/public/catalog/v1/metadata/`)

    url.searchParams.append("kind", resourceKind)
    url.searchParams.append("ref", ref)

    return await standardJsonRequest([url, { method: "GET" }])
  }

  async getCollectionDatasets({
    collectionId,
  }: {
    collectionId: string
  }): Promise<ApiResponse<APIResultsDto<DatasetDtoGet>>> {
    const ref = createCollectionRefernceName(collectionId)

    return (await this.getRelatedResources({
      resourceKind: ResourceKind.dataset,
      ref,
    })) as ApiResponse<APIResultsDto<DatasetDtoGet>>
  }

  async getResourceByIdOpen({
    resourceId,
    resourceType,
  }: {
    resourceId: string
    resourceType: ResourceTypeOdp
  }): Promise<ApiResponse<DatasetDtoGet | DataCollectionDtoGet>> {
    const ref = `${getResourceKindForResourceType(resourceType)}/${resourceId}`

    const url = new URL(`${API_URL}/api/public/catalog/v1/metadata/`)
    url.searchParams.append("qualified_name", ref)

    return await standardJsonRequest([url, { method: "GET" }])
  }

  async getCollectionByIdOpen(collectionId: string): Promise<ApiResponse<DataCollectionDtoGet | undefined>> {
    return (await this.getResourceByIdOpen({
      resourceId: collectionId,
      resourceType: ResourceTypeOdp.Collection,
    })) as ApiResponse<DataCollectionDtoGet>
  }

  async getDatasetByIdOpen(datasetsId: string): Promise<ApiResponse<DatasetDtoGet>> {
    return (await this.getResourceByIdOpen({
      resourceId: datasetsId,
      resourceType: ResourceTypeOdp.Dataset,
    })) as ApiResponse<DatasetDtoGet>
  }

  async getObservablesForResourceOpen({
    resourceId,
    resourceType,
  }: {
    resourceId: string
    resourceType: ResourceTypeOdp
  }): Promise<ApiResponse<APIResultsDto<ObservableDtoGet<any>>>> {
    const ref = `${getResourceKindForResourceType(resourceType)}/${resourceId}`

    return this.getRelatedResources({
      resourceKind: ResourceKind.observable,
      ref,
    }) as unknown as ApiResponse<APIResultsDto<ObservableDtoGet<any>>>
  }
}
