import { Box, Typography } from "@mui/material"
import React from "react"
import SignInButton from "../../../../components/SignInButton/SignInButton"
import { colorPalette } from "../../../../styling/theme"

const BlockedFilteringOverlay = () => {
  const Overflow = 5

  return (
    <Box
      sx={{
        position: "absolute",
        top: -Overflow,
        left: -Overflow,
        zIndex: 3,
        backgroundColor: colorPalette.deepPurpleA60,
        width: `calc(100% + ${2 * Overflow}px)`,
        height: `calc(100% + ${2 * Overflow}px)`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 1,
        flexDirection: "column",
      }}>
      <Typography variant="h6" sx={{ color: "white", p: 1, textAlign: "center" }}>
        Sign in for advanced search options
      </Typography>
      <SignInButton />
    </Box>
  )
}

export default React.memo(BlockedFilteringOverlay)
